/* Custom scrollbar for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Set the background color to transparent */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  