body {
   background-color: #f5f5f5;
  }
  
  .login-container {
   width: 100%;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
  }
  
  .login-container h1 {
   font-size: 24px;
   color: #333;
   margin-bottom: 20px;
  }
  
  .login-container form {
   display: flex;
   flex-direction: column;
  }
  
  .login-container form input {
   margin-bottom: 10px;
   padding: 8px;
   font-size: 14px;
   border: 1px solid #ddd;
   border-radius: 4px;
  }
  
  .login-container form button {
   margin-top: 20px;
   padding: 8px;
   font-size: 14px;
   background-color: #00b9f1;
   color: #fff;
   border: none;
   border-radius: 4px;
   cursor: pointer;
  }
  
  .login-container form button:hover {
   background-color: #00a1ce;
  }