
   
   .overlay {
    background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity as needed */
    height: 100%;
    
   }

   .btn-transparent {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 15px;
    font-size: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.btn-transparent:hover {
  background-color: #fff;
  color: black;
}

.search-btn {
  display: flex;
  align-items: center;
  gap: 4px;
}

.search-icon {
  width: 18px;
  height: 18px;
}

 
 .services .container {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s, transform 0.5s;

  }
/* 
  .goal-container  {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s, transform 0.5s;
  } */

  .services  {
    background-color: #07064d;
    color: whitesmoke;
  }

  
  
  .services .show-columns {
    opacity: 1;
    transform: translateY(0);
  }

  .card .backgroundEffect {
    bottom: 0;
    height: 0px;
    width: 100%
}



.card:hover {
    transform: scale(1.025);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
}

.card:hover .backgroundEffect {
    bottom: 0;
    height: 320px;
    width: 100%;
    position: absolute;
    z-index: -1;
    background: #1b9ce3;
    animation: popBackground 0.3s ease-in
}

.card:hover .backgroundEffect .icon {
    opacity: 1;
    transform: translateY(0)
}

.carousel-overlay{
    position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the transparency as needed */
  z-index: 1; /* Ensure the overlay is above the image */
}





.carousel-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure the captions appear above the overlay */
    color: #fff; /* Set the caption text color */
  }
  
  .carousel-caption h2 {
    font-size: 2.5rem; /* Adjust the font size as needed */
  }
  
  .carousel-caption p {
    font-size: 1.2rem; /* Adjust the font size as needed */
  }
  
  .light-orange {
    color: #ff8c00;
  }

  .content-container {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding: 50px;
    
  }

  @media (max-width: 768px) {
    .overlay {
      position: relative;
      padding: 20px;
    }

    .carousel-caption {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
      z-index: 1; /* Ensure the captions appear above the overlay */
      color: #fff; /* Set the caption text color */
    }
  
  }

@keyframes popBackground {
    0% {
        height: 20px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%
    }

    50% {
        height: 80px;
        border-top-left-radius: 75%;
        border-top-right-radius: 75%
    }

    75% {
        height: 160px;
        border-top-left-radius: 85%;
        border-top-right-radius: 85%
    }

    100% {
        height: 320px;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%
    }
}

  